export const IMPORT_PROJECT_MAPPING = {
    "Project ID": "projectId",
    "Project Number": "projectNumber",
    "Building Name": "name",
    "Sub Project Number": "number",
    "Project Name": "projectName",
    "Company Name": "companyName",
    "Project Principal": "projectPrincipal",
    "Market": "projectMarket",
    "Office": "officeLocation",
    "Street": "street",
    "City": "city",
    "State": "state",
    "Country": "country",
    "Postal Code": "postalCode",
    "Reporting Phase": "reportingPhase",
    "Reporting Year": "projectPhaseReportingYear",
    "Updated By": "projectPhaseUpdatedBy",
    "1. Project completed a significant design phase (SD, DD, or CD) or was in CA in the current reporting year": "projectCompletedDesignPhase",
    "2. Project includes scope that impacts energy use (ie: HVAC, envelope, or lighting design)": "projectImpaactsEnergyUse",
    "3. Project consists of contiguous building area of at least 3,000 SF": "projectConsistsContigousBuildArea",
    "4. Will this project be reported?": "willThisProjectBeReported",
    "Project Type": "projectType",
    "Construction Type": "constructionType",
    "Use Type 1 -- Primary Use": "useType1PrimaryUse",
    "Use Type 1 -- Primary Area": "useType1PrimaryArea",
    "Use Type 2 -- Secondary Use": "useType2SecondaryUse",
    "Use Type 2 -- Secondary Area": "useType2SecondaryArea",
    "Use Type 3 -- Tertiary Use": "useType3TertiaryUse",
    "Use Type 3 -- Tertiary Area": "useType3TertiaryArea",
    "Use Type 4 -- Quaternary Use": "useType4TertiaryUse",
    "Use Type 4 -- Quaternary Area": "useType4TertiaryArea",
    "Use Type 5 -- Quinary Use": "useType5TertiaryUse",
    "Use Type 5 -- Quinary Area": "useType5TertiaryArea",
    "Use Type -- Total Area": "useTypeTotalArea",
    "Has Energy Modeling": "hasEnergyModeling",
    "Estimated occupancy year": "estimatedOccupancyYear",
    "Energy code": "energyCode",
    "Energy modeling party": "energyModelingParty",
    "Energy Baseline Source": "energyBaselineSource",
    "Target Certification": "targetCertification",
    "Energy modeling tool": "energyModelingTool",
    "EUI Baseline (kBtu/sf/yr)": "baselineEUI",
    "LPD Baseline (watts/sf)": "baselineLPD",
    "Predicted EUI (kBtu/sf/yr)": "predictedEUI",
    "Predicted LPD (watts/sf)": "predictedLPD",
    "Has Embodied Carbon Modeling": "hasEmbodiedCarbonModeling",
    "Carbon Modeling Tool": "carbonModelingTool",
    "Building Lifetime Modeled": "buildingLifetimeModeling",
    "Carbon Modeling party": "carbonModelingParty",
    "Predicted Embodied Carbon (kgCOe/m2)": "predictedEmbodiedCarbon",
    "Scope": "scope",
    "LCA Stage Included In the model": "lcaStageIncluded",
}