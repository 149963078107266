import React, { useState, useEffect, useMemo } from "react";
import api from "../../../api/api";
import {
    Dialog,
    Grid,
    Button,
    Box,
    Typography,
    Divider,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import { convertExcelToJson, jsonToExcel } from "../../../utils";

import { VisuallyHiddenInput } from "../../EditProject/MEP2040/MEP2040";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { IMPORT_PROJECT_MAPPING } from "./ImportProjectMapping";

const styles = {
    width: "1rem",
    height: "1rem",
    color: "white",
    mr: "0.5rem",
};
const useIcon = (isLoading, Icon) => {
    return isLoading ? <CircularProgress size={"small"} sx={styles} /> : <Icon size={"small"} sx={styles} />
}

const BIT_MAP = { 'YES': 1, 'NO': 0 };
const convertToBit = (fieldValue) => {
    return BIT_MAP[fieldValue?.toString().toUpperCase()] || null
}
const handleTransformProjectData = (project) => {
    const transformedProject = {}
    for (let key in project) {
        transformedProject[IMPORT_PROJECT_MAPPING[key]] = project[key];
    }
    const {
        projectId,
        projectNumber,
        name,
        number,
        projectName,
        companyName,
        projectPrincipal,
        projectMarket,
        officeLocation,
        street,
        city,
        state,
        country,
        postalCode
    } = transformedProject;
    const projectInfo = {
        projectId: projectId?.toString(),
        projectNumber: projectNumber?.toString(),
        name: name?.toString(),
        number: number?.toString(),
        projectName: projectName?.toString(),
        companyName: companyName?.toString(),
        projectPrincipal: projectPrincipal?.toString(),
        projectMarket: projectMarket?.toString(),
        officeLocation: officeLocation?.toString(),
        street: street?.toString(),
        city: city?.toString(),
        state: state?.toString(),
        country: country?.toString(),
        postalCode: postalCode?.toString()
    }

    const {
        reportingPhase,
        projectPhaseReportingYear,
        projectPhaseUpdatedBy,
        projectCompletedDesignPhase,
        projectImpaactsEnergyUse,
        projectConsistsContigousBuildArea,
        willThisProjectBeReported,
        projectType,
        constructionType,
        useType1PrimaryUse,
        useType1PrimaryArea,
        useType2SecondaryUse,
        useType2SecondaryArea,
        useType3TertiaryUse,
        useType3TertiaryArea,
        useType4TertiaryUse,
        useType4TertiaryArea,
        useType5TertiaryUse,
        useType5TertiaryArea,
        useTypeTotalArea
    } = transformedProject;
    const projectEligibility = {
        reportingPhase: reportingPhase?.toString(),
        projectCompletedDesignPhase: convertToBit(projectCompletedDesignPhase),
        projectImpaactsEnergyUse: convertToBit(projectImpaactsEnergyUse),
        projectConsistsContigousBuildArea: convertToBit(projectConsistsContigousBuildArea),
        willThisProjectBeReported: convertToBit(willThisProjectBeReported),
        projectType: projectType?.toString(),
        constructionType: constructionType?.toString(),
        useType1PrimaryUse: useType1PrimaryUse?.toString(),
        useType1PrimaryArea: useType1PrimaryArea?.toString(),
        useType2SecondaryUse: useType2SecondaryUse?.toString(),
        useType2SecondaryArea: useType2SecondaryArea?.toString(),
        useType3TertiaryUse: useType3TertiaryUse?.toString(),
        useType3TertiaryArea: useType3TertiaryArea?.toString(),
        useType4TertiaryUse: useType4TertiaryUse?.toString(),
        useType4TertiaryArea: useType4TertiaryArea?.toString(),
        useType5TertiaryUse: useType5TertiaryUse?.toString(),
        useType5TertiaryArea: useType5TertiaryArea?.toString(),
        useTypeTotalArea: useTypeTotalArea?.toString()
    };

    const {
        hasEnergyModeling,
        estimatedOccupancyYear,
        energyCode,
        energyModelingParty,
        energyBaselineSource,
        targetCertification,
        energyModelingTool,
        baselineEUI,
        baselineLPD,
        predictedEUI,
        predictedLPD
    } = transformedProject
    const energyModeling = {
        hasEnergyModeling: convertToBit(hasEnergyModeling),
        estimatedOccupancyYear: estimatedOccupancyYear?.toString(),
        energyCode: energyCode?.toString(),
        energyModelingParty: energyModelingParty?.toString(),
        energyBaselineSource: energyBaselineSource?.toString(),
        targetCertification: targetCertification?.toString(),
        energyModelingTool: energyModelingTool?.toString(),
        baselineEUI: baselineEUI?.toString(),
        baselineLPD: baselineLPD?.toString(),
        predictedEUI: predictedEUI?.toString(),
        predictedLPD: predictedLPD?.toString(),
        projectPhaseReportingYear: projectPhaseReportingYear?.toString(),
        projectPhaseUpdatedBy: projectPhaseUpdatedBy?.toString(),
    }

    const {
        hasEmbodiedCarbonModeling,
        carbonModelingTool,
        buildingLifetimeModeling,
        carbonModelingParty,
        predictedEmbodiedCarbon,
        scope,
        lcaStageIncluded
    } = transformedProject
    const embodiedCarbonModeling = {
        hasEmbodiedCarbonModeling: convertToBit(hasEmbodiedCarbonModeling),
        carbonModelingTool: carbonModelingTool?.toString(),
        buildingLifetimeModeling: buildingLifetimeModeling?.toString(),
        carbonModelingParty: carbonModelingParty?.toString(),
        predictedEmbodiedCarbon: predictedEmbodiedCarbon?.toString(),
        scope: scope?.toString(),
        lcaStageIncluded: lcaStageIncluded?.toString(),
    }
    return {
        projectId,
        projectInfo,
        aia2030: {
            projectEligibility,
            energyModeling,
            embodiedCarbonModeling
        }
    }
}

const validateProjectsData = (projectsData, projectNumbers) => {
    console.log("projectNumbers", projectNumbers);
    return projectsData.map(project => {
        try {
            if (!projectNumbers.includes(project?.projectInfo?.projectNumber)) {
                throw `[${project.projectNumber}] : Project Number is not valid`
            }
        } catch (error) {
            return { isValid: false, error, project }
        }
        return { isValid: true, project }
    })
}

export const ImportProjects = (props) => {
    const {
        displayExportModal,
        setDisplayExportModal,
        projectsMetaData
    } = props;

    const [isImportLoading, setIsLoading] = useState(false);
    const [snackbar, showSnackbar] = useState({});

    const ImportIcon = useIcon(isImportLoading, FileUploadIcon);

    const handleImportTemplateDownload = () => {
        jsonToExcel({
            "Project Info + AIA 2030": [{
                "Project ID": "",
                "Project Number": "",
                "Building Name": "",
                "Sub Project Number": "",
                "Project Name": "",
                "Company Name": "",
                "Project Principal": "",
                "Market": "",
                "Office": "",
                "Street": "",
                "City": "",
                "State": "",
                "Country": "",
                "Postal Code": "",
                "Reporting Phase": "",
                "Reporting Year": "",
                "Updated By": "",
                "1. Project completed a significant design phase (SD, DD, or CD) or was in CA in the current reporting year": "",
                "2. Project includes scope that impacts energy use (ie: HVAC, envelope, or lighting design)": "",
                "3. Project consists of contiguous building area of at least 3,000 SF": "",
                "4. Will this project be reported?": "",
                "Project Type": "",
                "Construction Type": "",
                "Use Type 1 -- Primary Use": "",
                "Use Type 1 -- Primary Area": "",
                "Use Type 2 -- Secondary Use": "",
                "Use Type 2 -- Secondary Area": "",
                "Use Type 3 -- Tertiary Use": "",
                "Use Type 3 -- Tertiary Area": "",
                "Use Type 4 -- Quaternary Use": "",
                "Use Type 4 -- Quaternary Area": "",
                "Use Type 5 -- Quinary Use": "",
                "Use Type 5 -- Quinary Area": "",
                "Use Type -- Total Area": "",
                "Has Energy Modeling": "",
                "Estimated occupancy year": "",
                "Energy code": "",
                "Energy modeling party": "",
                "Energy Baseline Source": "",
                "Target Certification": "",
                "Energy modeling tool": "",
                "EUI Baseline (kBtu/sf/yr)": "",
                "LPD Baseline (watts/sf)": "",
                "Predicted EUI (kBtu/sf/yr)": "",
                "Predicted LPD (watts/sf)": "",
                "Has Embodied Carbon Modeling": "",
                "Carbon Modeling Tool": "",
                "Building Lifetime Modeled": "",
                "Carbon Modeling party": "",
                "Predicted Embodied Carbon (kgCOe/m2)": "",
                "Scope": "",
                "LCA Stage Included In the model": ""
            }],
        }, `Project_Data_Template.xlsx`);
    }

    const handleImportData = async (e) => {
        setIsLoading(true);
        const file = e.target.files?.[0];
        if (!file) {
            setIsLoading(false);
            return
        }
        try {
            const projectsData = await convertExcelToJson(file);
            if (file?.name?.split(".").at(-1)?.toLowerCase() !== "xlsx") {
                throw "Only XLSX files are allowed."
            } else if (!projectsData?.length) {
                throw "No data found in uploaded file."
            }
            // else if (projectsData?.some(project => !project['Project Number'])) {
            //     throw "\"Project Number\" canot be empty"
            // }
            console.log("projectsData", projectsData)
            if (projectsData?.length) {
                const modifiedProjectsData = projectsData.map(handleTransformProjectData)
                console.log("modifiedProjectData", modifiedProjectsData)
                const validationResult = validateProjectsData(modifiedProjectsData, projectsMetaData.map(_ => _.ProjectNo.toString()))
                console.log("validationResult", validationResult)
                const errorRecords = validationResult.filter(project => !project.isValid)

                if (errorRecords?.length) {
                    showSnackbar({
                        open: true,
                        message: `Pre Validation: There are ${errorRecords?.length} error records with invalid project numbers`,
                        variant: "error",
                    });
                    setIsLoading(false);
                    return
                }

                const res = await api.post("/updateProjectData", { projects: modifiedProjectsData });
                if (res.status === 200) {

                    const serverErrorRecords = res?.data?.result?.filter(row => row.value.error);
                    if (serverErrorRecords?.length) {
                        showSnackbar({
                            open: true,
                            message: `Server error: There are ${serverErrorRecords?.length} error records with invalid data`,
                            variant: "error",
                        });
                    } else {
                        showSnackbar({
                            open: true,
                            message: "Project data updated successfully!",
                            variant: "success",
                        });
                    }
                    setIsLoading(false);
                } else {
                    throw res.error;
                }
            }
        } catch (error) {
            showSnackbar({
                open: true,
                message: error,
                variant: "error",
            });
            console.log("error", error)
            setIsLoading(false);
        }
    }

    return (
        <>
            <Dialog
                open={displayExportModal}
                onClose={() => setDisplayExportModal(false)}
                sx={{
                    "& div.MuiPaper-root": {
                        maxWidth: "80vw",
                        maxHeight: "90vh",
                    },
                }}
            >
                <DialogTitle>
                    Import Project Data
                </DialogTitle>

                <Divider />
                <DialogContent>
                    <Grid container>

                        <Grid item sx={{ p: 2 }} xs={12} sm={12} md={12} >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, justifyContent: 'center', height: "100%", textAlign: 'center' }}>
                                <Typography variant="body1" color="GrayText" sx={{ whiteSpace: 'pre-line', mb: 2 }}>
                                    {`Upload project data containing Project Info + AIA 2030. 
                                Please follow the below template for column naming.
                            `}
                                    <Button onClick={handleImportTemplateDownload} sx={{ mt: 1, textTransform: 'none' }} size={"small"} variant="text">Project_Data_Template.xlsx</Button>
                                </Typography>
                                <Button
                                    sx={{ marginLeft: "0.5rem" }}
                                    component="label"
                                    variant={"contained"}
                                    size={"small"}
                                    disabled={isImportLoading}
                                >
                                    {ImportIcon}
                                    Import
                                    <VisuallyHiddenInput type="file" onChange={handleImportData} />
                                </Button>
                                <Typography variant="caption" color="ActiveCaption" sx={{ whiteSpace: 'pre-line', mt: 2 }}>
                                    {`*If the provided Project ID & Project Number matches an existing project data, 
                            this action will override the data with the newly provided data. `}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {snackbar?.open && <Alert

                        onClose={() => showSnackbar({})}
                        severity={snackbar.variant}
                        sx={{ width: "100%" }}
                        elevation={6}
                        variant="filled"
                    >
                        {snackbar.message}
                    </Alert>}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box
                        p={2}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <Box component={"span"}></Box>
                        <Box>
                            <Button
                                size={"small"}
                                onClick={() => setDisplayExportModal(false)}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog >

        </>
    );
};

export default ImportProjects;
